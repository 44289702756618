import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { X } from 'lucide-react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/shadcn/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/Form'
import { Skeleton } from '@/components/shadcn/Skeleton'
import { Input } from '@/components/shadcn/Input'
import { Button } from '@/components/shadcn/Button'
import useGenerateArticle from '@/api/stories/mutations/useGenerateArticle'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'
import RoutePaths from '@/routes/routes'
import { Alert, AlertDescription, AlertTitle } from '@/components/shadcn/Alert'
import { formSchema, GenerateCollectionForm } from './schema'

type Props = {
  onClose: () => void
}

export const GenerateCollection = ({ onClose }: Props) => {
  const { mutate, isPending, isError } = useGenerateArticle()

  const { slug } = useParams()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      path: '',
    },
  })

  const navigate = useNavigate()

  const onSubmit = (data: GenerateCollectionForm) => {
    const editorial = getEditorialFromSlug(slug)

    mutate(
      { filter: data, editorial: editorial! },
      {
        onSuccess: data => {
          const story = data[0]

          if (!slug) throw new Error('Slug not found')

          const path = generatePath(RoutePaths.STORY_DETAIL, { storyId: story.id, slug })

          navigate(path)

          onClose()
        },
      }
    )
  }

  if (isPending)
    return (
      <>
        <DialogHeader>
          <DialogTitle>Generování kolekce</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-4">
          <Skeleton className="h-32 w-32 rounded-full" />
          <div className="flex flex-col space-y-4">
            <Skeleton className="h-6 w-52" />
            <Skeleton className="h-6 w-52" />
          </div>
        </div>
      </>
    )

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <DialogHeader className="mb-4">
          <DialogTitle>Generování kolekce ze článku</DialogTitle>
        </DialogHeader>
        {isError && (
          <Alert variant="destructive">
            <AlertTitle>Chyba!</AlertTitle>
            <AlertDescription>
              Při generování kolekce z článku došlo k chybě. Opravdu článek existuje a obsahuje fotografie v galerii?
            </AlertDescription>
          </Alert>
        )}
        <FormField
          control={form.control}
          name="path"
          render={({ field }) => (
            <FormItem>
              <FormLabel>URL článku</FormLabel>
              <FormControl>
                <Input placeholder="https://isport.blesk.cz/clanek/fotbal.html" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <Button variant="ghost" className="flex gap-2.5" onClick={onClose}>
            <X className="size-4" />
            Zrušit
          </Button>
          <Button variant="default" type="submit">
            Vygenerovat kolekci
          </Button>
        </DialogFooter>
      </form>
    </Form>
  )
}
