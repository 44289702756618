import { Button } from '@/components/shadcn/Button'
import { cn } from '@/utils/shadcn/cn'
import { useMultiStepper } from '@/components/Stepper/useMultiStepper'
import useStoryPageTemplates from '@/api/pages/queries/useStoryPageTemplates'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { DialogFooter } from '@/components/shadcn/Dialog'
import { Alert, AlertDescription, AlertTitle } from '@/components/shadcn/Alert'
import TemplatesSkeleton from './TemplatesSkeleton'

const TemplatePicker = () => {
  const { nextStep, setState, state } = useMultiStepper()
  const { data, isPending, isError } = useStoryPageTemplates()

  const continueWithoutTemplate = () => {
    setState({ idTemplate: null })
    nextStep()
  }

  if (isError)
    return (
      <Alert variant="destructive">
        <AlertTitle>Chyba</AlertTitle>
        <AlertDescription>Při načítání šablon došlo k chybě.</AlertDescription>
      </Alert>
    )

  return (
    <>
      <div className="grid grid-cols-3 justify-items-center">
        {isPending ? (
          <TemplatesSkeleton />
        ) : (
          data.map(template => (
            <div
              key={template.name}
              aria-hidden="true"
              onClick={() => setState({ idTemplate: template.id })}
              className={cn(
                'flex w-56 cursor-pointer flex-col items-start gap-2 rounded-lg p-5 transition-all duration-300',
                state.idTemplate === template.id && 'bg-zinc-100 shadow-sm'
              )}
            >
              <img src={getStoryAssetImageUrl(template.coverS3Key ?? '')} alt={template.name ?? ''} className="w-full" />
              <h3>{template.name}</h3>
              <p className="text-sm font-normal text-zinc-500">{template.description}</p>
            </div>
          ))
        )}
      </div>
      <DialogFooter className="flex w-full justify-end">
        <Button onClick={continueWithoutTemplate} variant="ghost">
          Pokračovat bez šablony
        </Button>
        <Button onClick={nextStep}>Pokračovat k editaci</Button>
      </DialogFooter>
    </>
  )
}

export default TemplatePicker
