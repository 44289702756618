import { handleError } from '@/api/errors'
import {
  type AddStoryBody,
  type AddStoryResponse,
  ArchiveStoryResponse,
  type DeleteStoryParams,
  type DeleteStoryResponse,
  GenerateArticleBody,
  GenerateArticleResponse,
  GetStoriesBody,
  type GetStoriesResponse,
  GetStoryPagesParams,
  GetStoryPagesResponse,
  type GetStoryParams,
  type GetStoryResponse,
  OrderPagesBody,
  OrderStoriesBody,
  type PublishStoryResponse,
  type SaveStoryResponse,
} from '@/api/stories/types'
import { getClient } from '../utils/getClient'

export const getStories = async (data?: GetStoriesBody): Promise<GetStoriesResponse> => {
  const client = await getClient()
  return client
    .post('simple-stories/filter', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const addStory = async (data: AddStoryBody): Promise<AddStoryResponse> => {
  const client = await getClient()
  return client
    .post('simple-stories/', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const getStory = async ({ storyId }: GetStoryParams): Promise<GetStoryResponse> => {
  const client = await getClient()
  return client
    .get(`simple-stories/${encodeURIComponent(storyId)}`)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const getStoryPages = async ({ storyId }: GetStoryPagesParams): Promise<GetStoryPagesResponse> => {
  const client = await getClient()
  return client
    .get(`simple-stories/${encodeURIComponent(storyId)}/pages`)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const saveStory = async (data: AddStoryBody): Promise<SaveStoryResponse> => {
  const client = await getClient()
  return client
    .put(`simple-stories/${encodeURIComponent(data.id)}`, data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const orderPages = async (data: OrderPagesBody[]): Promise<void> => {
  const client = await getClient()
  return client
    .post('simple-stories/pages/order', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const orderStories = async (data: OrderStoriesBody[]): Promise<void> => {
  const client = await getClient()
  return client
    .post('simple-stories/stories/order', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const deleteStory = async ({ storyId }: DeleteStoryParams): Promise<DeleteStoryResponse> => {
  const client = await getClient()
  return client
    .delete(`simple-stories/${encodeURIComponent(storyId)}`)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const publishStory = async ({ ...data }): Promise<PublishStoryResponse> => {
  const client = await getClient()
  return client
    .post('simple-stories/publish', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const archiveStory = async ({ ...data }): Promise<ArchiveStoryResponse> => {
  const client = await getClient()
  return client
    .post('simple-stories/archive', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}

export const generateArticle = async (data: GenerateArticleBody): Promise<GenerateArticleResponse> => {
  const client = await getClient()
  return client
    .post('simple-stories/generate', data)
    .then(res => res.data)
    .catch((e: Error) => {
      handleError(e)
    })
}
