import { useEffect, useState } from 'react'
import { LoaderCircle } from 'lucide-react'
import { cn } from '@/utils/shadcn/cn'

type Props = {
  threshold?: number
  className?: string
}

const Loader = ({ threshold = 100, className }: Props) => {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldDisplay(true)
    }, threshold)
    return () => {
      clearTimeout(timeout)
    }
  }, [threshold])

  return <LoaderCircle className={cn('animate-spin text-slate-700', className, shouldDisplay ? 'visible' : 'invisible')} />
}

export default Loader
