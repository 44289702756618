import { type Page } from '@/api/pages/types'

interface Pagination {
  skip?: number
  limit?: number
}

interface QueryOptions {
  pagination?: Pagination
  include?: boolean
}

export interface Story {
  id: string
  title: string
  dateCreated?: Date
  dateUpdated?: Date
  pages: Page[]
  published?: Date | null
  archive: boolean
  order: number
  editorial: Editorial
  generated: boolean
}

export enum StoryStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export interface GetStoriesBody extends QueryOptions {
  filter: {
    editorial?: Editorial | null
    type?: StoryStatus | null
    search?: string | null
    dateCreated?: {
      startDate?: Date | null
      endDate?: Date | null
    }
  }
}

export interface AddStoryBody {
  id: string
  title: string
  editorial: Editorial
}

export interface OrderPagesBody {
  id: string
  order: number
}

export interface OrderStoriesBody {
  id: string
  order: number
}

export interface PublishStoryBody {
  id: string
  published: Date | null
  ttl: Date | null
}

export interface ArchiveStoryBody {
  id: string
  archive: boolean
}

export interface GenerateArticleBody {
  filter: { path: string }
  editorial: Editorial
}

export interface SaveStoryBody extends Story {}

export interface GetStoryResponse extends Story {}
export interface AddStoryResponse extends Story {}
export interface SaveStoryResponse extends Story {}
export interface DeleteStoryResponse extends Story {}
export interface SetStoryStatusResponse extends Story {}
export interface PublishStoryResponse extends Story {}
export interface ArchiveStoryResponse extends Story {}
export interface GenerateArticleResponse extends Story {}

export type GetStoriesResponse = Story[]

export type GetStoryPagesResponse = Page[]

export interface GetStoryParams {
  storyId: string
}

export interface GetStoryPagesParams {
  storyId: string
}

export interface DeleteStoryParams {
  storyId: string
}

export enum Editorial {
  BLESK = 'BLESK',
  EVROPA_2 = 'EVROPA_2',
  ISPORT = 'ISPORT',
  AHA = 'AHA',
  AUTO = 'AUTO',
}
