import { Sparkles } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent, DialogTrigger } from '@/components/shadcn/Dialog'
import { GenerateCollection } from '@/forms/GenerateCollection'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'
import { Editorial } from '@/api/stories/types'
import { Button } from '../shadcn/Button'

const GenerateCollectionDialog = () => {
  const [open, setOpen] = useState(false)

  const { slug } = useParams()

  const editorial = getEditorialFromSlug(slug)

  if (editorial === Editorial.EVROPA_2) return null

  return (
    <Dialog open={open} onOpenChange={value => setOpen(value)}>
      <DialogTrigger onClick={() => setOpen(true)}>
        <Button variant="secondary" className="flex gap-2.5 px-4 py-2 shadow-none">
          <Sparkles className="size-4" />
          Vygenerovat kolekci
        </Button>
      </DialogTrigger>
      <DialogContent>
        <GenerateCollection onClose={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}

export default GenerateCollectionDialog
