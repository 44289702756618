import { useFormContext } from 'react-hook-form'
import { PencilIcon, Share } from 'lucide-react'
import moment from 'moment'
import { Button } from '@/components/shadcn/Button'
import { useMultiStepper } from '@/components/Stepper/useMultiStepper'
import Loader from '@/components/Loader'

interface ActionsPageFormProps {
  isLoading: boolean
  isNew: boolean
  action: ({ data }: any) => void
}

const ActionsPageForm = ({ isLoading, action, isNew }: ActionsPageFormProps) => {
  const { prevStep } = useMultiStepper()

  const { handleSubmit } = useFormContext()
  const handleButtonSubmit = (e: React.MouseEvent<HTMLButtonElement>, publish?: boolean) => {
    e.preventDefault()
    handleSubmit(values => {
      if (isNew && !publish) action({ ...values, published: null, ttl: null })
      if (isNew && publish)
        action({
          ...values,
          published: moment().toDate(),
          ttl: moment().add(7, 'days').toDate(),
        })
      if (!isNew) action({ ...values })
    })()
  }

  return (
    <div className="flex w-full items-end justify-between gap-4">
      <Button variant="ghost" className="flex space-x-2" onClick={prevStep}>
        <PencilIcon className="size-4" />
        <span>Změnit šablonu</span>
      </Button>
      {isNew ? (
        <div className="flex items-end justify-end gap-4">
          <Button className="flex gap-2" variant="ghost" type="submit" disabled={isLoading} onClick={e => handleButtonSubmit(e, true)}>
            <Share className="size-4" />
            <p>Publikovat</p>
          </Button>
          <Button variant="default" type="submit" disabled={isLoading} onClick={handleButtonSubmit}>
            {isLoading ? <Loader /> : <p>Vytvořit příběh</p>}
          </Button>
        </div>
      ) : (
        <Button variant="default" type="submit" disabled={isLoading} onClick={handleButtonSubmit}>
          {isLoading ? <Loader /> : <p>Uložit příběh</p>}
        </Button>
      )}
    </div>
  )
}

export default ActionsPageForm
