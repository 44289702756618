import { Suspense } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Header from '@/components/Header'
import NavBar from '@/components/Header/components/NavBar'
import ThemeProvider from '@/providers/ThemeProvider'
import { useAuth } from '@/hooks/useAuth'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'
import Forbidden from '@/components/Forbidden'
import OutlinkBar from '@/components/OutlinkBar'
import ToolBar from '@/components/Header/components/ToolBar'
import LoadingScreen from '@/components/LoadingScreen'
import Wrapper from './Wrapper'

const EditorialLayout = () => {
  const { slug } = useParams()

  const { editorial, isAdmin } = useAuth()

  const assertedEditorial = getEditorialFromSlug(slug)

  if (assertedEditorial !== editorial && !isAdmin) return <Forbidden />

  return (
    <Suspense fallback={<LoadingScreen />}>
      <ThemeProvider>
        <Header />
        <OutlinkBar />
        <ToolBar />
        <NavBar />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </ThemeProvider>
    </Suspense>
  )
}

export default EditorialLayout
