import { ReactNode } from 'react'
import { cn } from '@/utils/shadcn/cn'
import { ScrollArea } from '../shadcn/ScrollArea'

type Props = {
  children: ReactNode
}

const DialogScrollArea = ({ children }: Props) => {
  return (
    <ScrollArea className={cn('-m-4 max-h-[calc(100vh-12rem)] overflow-auto')} type="hover">
      <div className="p-4">{children}</div>
    </ScrollArea>
  )
}

export default DialogScrollArea
