import { useRef } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { useFormContext } from 'react-hook-form'
import { recalculateCropBox } from '@/utils/cropBox'

interface ImageCropperProps {
  image: File | null
}

const ImageCropper = ({ image }: ImageCropperProps) => {
  const { setValue } = useFormContext()
  const cropperRef = useRef<ReactCropperElement>(null)

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper
    if (!cropper) return

    const cropBBox = cropper.getCropBoxData()
    const imageData = cropper.getImageData()
    const container = cropper.getContainerData()

    if (!cropBBox || !imageData) return

    const recalculatedCropBox = recalculateCropBox(imageData, cropBBox, container)

    setValue('tempCropBBox', recalculatedCropBox)
  }

  return (
    <Cropper
      src={image ? URL.createObjectURL(image) : ''}
      style={{ height: '100%', width: '100%' }}
      initialAspectRatio={9 / 16}
      aspectRatio={9 / 16}
      viewMode={2}
      zoomable={false}
      autoCropArea={1}
      guides
      crop={onCrop}
      ref={cropperRef}
    />
  )
}

export default ImageCropper
