/* eslint-disable react/display-name */
import { memo, useState } from 'react'
import { FileImage, FileVideo, Sparkles } from 'lucide-react'
import { Page } from '@/api/pages/types'
import { Skeleton } from '@/components/shadcn/Skeleton'
import PlaceholderV2 from '@/assets/placeholder-img.png'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { cn } from '@/utils/shadcn/cn'
import { getFileMediaType } from '@/utils/fileExtensions'

const StoryPageImage = memo(({ page }: { page: Page }) => {
  const [isLoading, setIsLoading] = useState(true)
  const mediaType = getFileMediaType(page.imageS3Key)
  const mediaSource = mediaType === 'video' ? page.coverS3Key : page.imageS3Key
  const mediaUrl = mediaSource ? getStoryAssetImageUrl(mediaSource) : PlaceholderV2
  return (
    <div className="relative">
      <img
        src={mediaUrl}
        className={cn('h-80 w-44 max-w-44 rounded-md object-cover', isLoading && 'hidden')}
        alt="story"
        onLoad={() => setIsLoading(false)}
      />
      <div className="absolute left-2 top-2 z-10 flex h-6 w-6 items-center justify-center rounded bg-white/70 text-slate-700">
        {mediaType === 'video' && <FileVideo size={14} />}
        {mediaType === 'image' && <FileImage size={14} />}
      </div>
      {page.generated && (
        <div className="absolute right-2 top-2 z-10 flex h-6 w-6 items-center justify-center rounded border border-white/50 bg-slate-700 text-white">
          <Sparkles className="size-4" />
        </div>
      )}
      <Skeleton className={cn('h-80 w-44 max-w-48 rounded-md object-contain', !isLoading && 'hidden')} />
    </div>
  )
})

export default StoryPageImage
