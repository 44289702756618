import { type Story } from '@/api/stories/types'
import CardInfoItem from '@/components/Card/CardInfoItem'
import CardInfoGroup from '@/components/Card/CardInfoGroup'
import getFormalizedDateFormat from '@/utils/date/getFormalizedDateFormat'
import EditorialLogo from '../EditorialLogo/index.tsx'

interface StoryCardBarProps {
  story: Story
}

const StoryCardBar = ({ story }: StoryCardBarProps) => {
  return (
    <div className="relative flex flex-col items-start gap-2.5">
      <EditorialLogo type="circle" className="size-10" />
      <p className="text-md break-all text-left font-bold">
        {story.title?.slice(0, 50)}
        {story.title.split('').length > 50 ? '...' : ''}
      </p>
      <CardInfoGroup>
        <CardInfoItem>Vytvořeno: {getFormalizedDateFormat(story.dateCreated)}</CardInfoItem>
      </CardInfoGroup>
    </div>
  )
}
export default StoryCardBar
