import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'
import { toast } from 'sonner'
import { useAuth } from '@/hooks/useAuth'
import Input from '@/forms/inputs/Input'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/Form'
import { Button } from '@/components/shadcn/Button'
import { PasswordInput } from '@/forms/inputs/PasswordInput'
import Loader from '@/components/Loader'
import { loginFormSchema } from './Schema'

const LoginForm = () => {
  const { handleSignIn, error } = useAuth()
  const [isLoading, setLoading] = useState(false)

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: { username: '', password: '' },
  })

  const onFinish = async (values: z.infer<typeof loginFormSchema>) => {
    setLoading(true)
    await handleSignIn(values)
    setLoading(false)
  }

  useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFinish)}>
        <div className="flex flex-col space-y-4">
          <FormField
            name="username"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-2">
                <FormLabel>E-mail</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="vas@email.cz" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="password"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-2">
                <FormLabel>Heslo</FormLabel>
                <FormControl>
                  <PasswordInput {...field} placeholder="***********" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button variant="default" className="mt-6 w-full" type="submit">
          {isLoading ? <Loader className="text-slate-200" /> : <p>Přihlásit se</p>}
        </Button>
      </form>
    </Form>
  )
}

export default LoginForm
