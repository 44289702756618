import React from 'react'
import { Search as SearchIcon, XIcon } from 'lucide-react'
import { cn } from '@/utils/shadcn/cn'

export interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
  clearInput: () => void
}

const Search = React.forwardRef<HTMLInputElement, SearchProps>(({ className, inputClassName, clearInput, ...props }, ref) => {
  return (
    <div className={cn('flex h-10 items-center rounded-md border border-input px-3 text-sm ring-offset-background', className)}>
      <SearchIcon className="h-4 w-4" />
      <input
        {...props}
        ref={ref}
        spellCheck={false}
        className={cn(
          'w-full bg-transparent p-2 placeholder:text-white/60 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          inputClassName
        )}
      />
      <button onClick={clearInput} aria-label="Reset" className={cn(props.value ? 'visible' : 'invisible')}>
        <XIcon className="size-4" />
      </button>
    </div>
  )
})

Search.displayName = 'Search'

export default Search
