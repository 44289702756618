import { forwardRef } from 'react'

const FilterItem = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  return (
    <div ref={ref} className="flex h-14 cursor-pointer items-center space-x-4 rounded-xl p-3 text-sm hover:bg-gray-50" {...props}>
      {props.children}
    </div>
  )
})

FilterItem.displayName = 'FilterItem'

export default FilterItem
