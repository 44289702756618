import { useParams } from 'react-router-dom'
import useStory from '@/api/stories/queries/useStory'
import DetailStoryCard from '@/components/DetailStoryCard'
import PageFormFetcher from '@/forms/AddPage'
import { useModal } from '@/stores/useModal'
import Loader from '@/components/Loader'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/shadcn/Dialog'
import StoryError from './errors/storyError'

const NewStoryPage = () => {
  const { storyId } = useParams<{ storyId?: string }>()
  const { data, isLoading, isError } = useStory({ storyId: storyId ?? '' })
  const { isOpen, onClose } = useModal()

  if (isLoading) {
    return <Loader />
  }

  if (isError || !data) {
    return <StoryError />
  }

  return (
    <div className="flex h-full w-full text-balance px-11 pb-12">
      <div className="flex h-full w-full flex-col gap-5">
        <DetailStoryCard story={data} />
      </div>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-h-[calc(100vh-2rem)] w-full max-w-3xl overflow-hidden">
          <DialogHeader>
            <DialogTitle>Vytvořit novou stránku příběhu</DialogTitle>
          </DialogHeader>
          <PageFormFetcher storyId={storyId ?? ''} storyTitle={data.title} numberOfPages={data.pages.length} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default NewStoryPage
