import { generatePath, Link } from 'react-router-dom'
import EditorialLogo from '@/components/EditorialLogo'
import { Editorial } from '@/api/stories/types'
import RoutePaths from '@/routes/routes'
import { getSlugFromEditorial } from '@/utils/slugEditorialConversion'

type Props = {
  editorial: Editorial
}

const EditorialCard = ({ editorial }: Props) => {
  const slug = getSlugFromEditorial(editorial)

  return (
    <Link
      to={generatePath(RoutePaths.EDITORIAL, { slug: slug! })}
      key={editorial}
      role="button"
      aria-label={`Přejít na obsah redakce ${editorial}`}
      className="flex h-24 w-full items-center justify-center rounded-lg border border-slate-200 transition-colors hover:bg-gray-100"
    >
      <EditorialLogo editorial={editorial} className="h-8" />
    </Link>
  )
}

export default EditorialCard
