import { useState } from 'react'
import { CheckCircle, FileImage, FileVideo, LoaderCircle } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import { filesize } from 'filesize'
import { FileInput, FileUploader, FileUploaderContent, FileUploaderItem } from '@/components/shadcn/DropZone'
import { Progress } from '@/components/shadcn/Progress'
import { getFileMediaType, shortenFileNameWithExtension } from '@/utils/fileExtensions'
import { cn } from '@/utils/shadcn/cn'
import { Separator } from '@/components/shadcn/Separator'
import { UploadProgressState } from '../AddPage/handlers/useOnImageChange'

const FileSvgDraw = () => {
  return (
    <>
      <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.3337 1.3335V8.00016C18.3337 8.88422 18.6848 9.73206 19.31 10.3572C19.9351 10.9823 20.7829 11.3335 21.667 11.3335H28.3337M15.0003 18.0002V28.0002M15.0003 18.0002L20.0003 23.0002M15.0003 18.0002L10.0003 23.0002M20.0003 1.3335H5.00033C4.11627 1.3335 3.26842 1.68469 2.6433 2.30981C2.01818 2.93493 1.66699 3.78277 1.66699 4.66683V31.3335C1.66699 32.2176 2.01818 33.0654 2.6433 33.6905C3.26842 34.3156 4.11627 34.6668 5.00033 34.6668H25.0003C25.8844 34.6668 26.7322 34.3156 27.3573 33.6905C27.9825 33.0654 28.3337 32.2176 28.3337 31.3335V9.66683L20.0003 1.3335Z"
          stroke="#374151"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <p className="my-2 text-[#374151]">
        <span className="font-semibold">
          <u>Vyberte soubor</u> nebo ho přetáhněte sem
        </span>
      </p>
      <p className="text-sm text-gray-400">Podporované formáty: JPEG, PNG, WEBP, MP4</p>
      <p className="text-sm text-gray-400">Maximální velikost souboru: 50 MB</p>
    </>
  )
}

interface DropZoneUploaderProps {
  progress: UploadProgressState
  onMediaChange: (data: File | null, getValues: any, setValue: any, setError: any) => void
}

// TODO connect dropzone to existing zod handler?
const DropZoneUploader = ({ onMediaChange, progress }: DropZoneUploaderProps) => {
  const { getValues, setValue, setError } = useFormContext()
  const [files, setFiles] = useState<File[] | null>(null)

  const dropZoneConfig = {
    maxFiles: 5,
    maxSize: 1024 * 1024 * 50,
    multiple: false,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.webp'], 'video/mp4': ['.mp4'] },
  }

  const handleImageDropped = (value: File[] | null) => {
    if (value && value.length > 0) {
      onMediaChange(value[0], getValues, setValue, setError)
      setFiles(value)
    } else {
      setFiles(null)
      onMediaChange(null, getValues, setValue, setError)
    }
  }

  return (
    <FileUploader
      value={files}
      onValueChange={handleImageDropped}
      dropzoneOptions={dropZoneConfig}
      className="relative rounded-lg bg-background"
    >
      {!files && (
        <FileInput className="outline-dashed outline-1 outline-white transition-colors hover:bg-gray-50">
          <div className="flex w-full flex-col items-center justify-center rounded-lg border border-dashed border-gray-300 px-6 py-3">
            <FileSvgDraw />
          </div>
        </FileInput>
      )}
      <FileUploaderContent>
        {files &&
          files.length > 0 &&
          files.map((file, i) => (
            <FileUploaderItem key={file.name} index={i} className="h-20 bg-gray-50 px-4">
              <div className="flex w-full flex-col">
                <div className="flex gap-2">
                  {getFileMediaType(file.name) === 'video' && <FileVideo className="size-10" strokeWidth="1px" />}
                  {getFileMediaType(file.name) === 'image' && <FileImage className="size-10" strokeWidth="1px" />}
                  <div className="flex w-full flex-1 flex-col justify-between text-slate-400">
                    <p className="text-sm font-semibold text-slate-700">{shortenFileNameWithExtension(file.name, 30)}</p>
                    <div className="flex items-center space-x-2 text-xs">
                      <p className="text-slate-400">
                        {filesize(progress.transferredBytes)} z {filesize(progress.totalBytes)}
                      </p>
                      <Separator orientation="vertical" />
                      <div className="flex items-center space-x-2 text-xs">
                        {progress.percent === 100 ? (
                          <>
                            <CheckCircle className="size-3" />
                            <span>Nahráno</span>
                          </>
                        ) : (
                          <>
                            <LoaderCircle className="size-3 animate-spin" />
                            <span>Nahrávání</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Progress
                  className={cn('mt-3 h-2 w-full', 'transition-all duration-500', progress?.percent === 100 && 'mt-0 h-0 opacity-0')}
                  value={progress?.percent}
                />
              </div>
            </FileUploaderItem>
          ))}
      </FileUploaderContent>
    </FileUploader>
  )
}

export default DropZoneUploader
