interface CardInfoItemProps {
  children: React.ReactNode
}

const CardInfoItem = ({ children }: CardInfoItemProps) => (
  <div className="flex items-center gap-2 text-xs text-gray-400">
    <p>{children}</p>
  </div>
)

export default CardInfoItem
