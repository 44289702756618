import { Share } from 'lucide-react'
import moment from 'moment'
import { useQueryClient } from '@tanstack/react-query'
import usePublishStory from '@/api/stories/mutations/usePublishStory'
import { type Story } from '@/api/stories/types'
import { Button } from '@/components/shadcn/Button'
import { PublishStatus } from '@/constants/publishConst'
import getPublishedStatus from '@/utils/getPublishedStatus'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'
import { CONFIRM_TEXT } from '@/constants/ConfirmText'
import Keys from '@/api/keys'
import Loader from '@/components/Loader'

interface StoryCardActionsProps {
  story: Story
}

const StoryCardActions = ({ story }: StoryCardActionsProps) => {
  const { mutate, isPending } = usePublishStory()
  const publishedStatus = getPublishedStatus(story)
  const { openDialog } = useConfirmActionDialog()
  const client = useQueryClient()

  // TODO uncomment when copy to clipboard is implemented
  return (
    <div className="flex flex-col items-center justify-center gap-2 transition-all duration-200 ">
      {publishedStatus === PublishStatus.DRAFT && (
        <Button
          variant="ghost"
          className="z-50 flex min-w-full justify-start gap-2"
          onClick={e => {
            e.stopPropagation()
            openDialog({
              title: CONFIRM_TEXT.PUBLISH_STORY.title,
              description: CONFIRM_TEXT.PUBLISH_STORY.description,
              onCancel: () => {},
              onConfirm: () => {
                mutate(
                  { id: story.id, published: moment().toDate(), ttl: moment().add(7, 'days').toDate() },
                  {
                    onSuccess: () => {
                      client.invalidateQueries({ queryKey: [Keys.STORIES] })
                    },
                  }
                )
              },
            })
          }}
        >
          {!isPending ? (
            <>
              <Share />
              <p>Publikovat</p>
            </>
          ) : (
            <Loader />
          )}
        </Button>
      )}
      {/* <Button
        variant="ghost"
        className="z-10 flex min-w-full justify-start gap-2 text-slate-600 hover:text-slate-800"
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <CodeXml />
        <p>Zkopírovat kód</p>
      </Button> */}
    </div>
  )
}

export default StoryCardActions
