import { useEffect, useState } from 'react'
import { LoaderCircle } from 'lucide-react'
import { cn } from '@/utils/shadcn/cn'

const LoadingScreen = ({ threshold = 100 }) => {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldDisplay(true)
    }, threshold)
    return () => {
      clearTimeout(timeout)
    }
  }, [threshold])

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <LoaderCircle className={cn('size-12 animate-spin text-slate-500', shouldDisplay ? 'visible' : 'invisible')} />
    </div>
  )
}

export default LoadingScreen
