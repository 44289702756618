import { useSearchParams } from 'react-router-dom'

import { useEffect } from 'react'
import useStoryPage from '@/api/pages/queries/useStoryPage'
import { MultiStepperProvider } from '@/components/Stepper/useMultiStepper'
import { MultiStepper } from '@/components/Stepper/MultiStepper'
import { Page } from '@/api/pages/types'
import FormLoadingSkeleton from './Skeleton'
import PageForm from './Form'
import TemplatePicker from '../TemplatePicker'

interface PageFormFetcherProps {
  storyId: string
  storyTitle: string
  numberOfPages: number
}

const PageFormFetcher = ({ storyId, storyTitle, numberOfPages }: PageFormFetcherProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageId = searchParams.get('pageId')
  const isNew = !pageId

  const { data: fetchedData, isLoading } = useStoryPage({ pageId: pageId!, storyId })

  const emptyPageData: Page = {
    id: '',
    idStory: storyId,
    label: `${storyTitle} - Příběh ${numberOfPages + 1}`,
    text: '',
    emoji: '',
    link: '',
    published: null,
    imageS3Key: '',
    coverS3Key: null,
    updateImage: false,
    ttl: null,
    idTemplate: null,
    order: 0,
    generated: false,
  }
  const defaultValues = isNew && !fetchedData ? emptyPageData : fetchedData || emptyPageData

  useEffect(() => {
    return () => {
      setSearchParams('')
    }
  }, [setSearchParams])

  const initialTemplateState = { idTemplate: defaultValues?.idTemplate ?? null }

  if (isLoading) return <FormLoadingSkeleton />

  return (
    <MultiStepperProvider totalSteps={2} initialStep={isNew ? 0 : 1} initState={initialTemplateState}>
      <MultiStepper steps={[<TemplatePicker key={0} />, <PageForm initialValues={defaultValues} isNew={isNew} key={1} />]} />
    </MultiStepperProvider>
  )
}

export default PageFormFetcher
