import { ChevronDown, LogOut } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/shadcn/DropdownMenu'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/shadcn/Button'
import EditorialTitle from '@/components/EditorialTitle'
import getInitialsFromEmail from '@/utils/getInitialsFromEmail'
import { Avatar, AvatarFallback, AvatarImage } from '../shadcn/Avatar'

const UserMenu = () => {
  const { data, handleSignOut, editorial, isAdmin } = useAuth()
  const email = data?.tokens?.idToken?.payload.email as string

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex h-full select-none space-x-4 text-white">
          <Avatar className="size-8">
            <AvatarImage src={undefined} />
            <AvatarFallback className="bg-gray-200 text-gray-800">{getInitialsFromEmail(email)}</AvatarFallback>
          </Avatar>
          <div className="hidden items-center space-x-2 md:flex">
            <span>{email}</span>
            <ChevronDown className="size-4" />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-64">
        <DropdownMenuLabel className="text-sm font-medium text-gray-700">{email}</DropdownMenuLabel>
        {!isAdmin && (
          <DropdownMenuLabel className="text-sm font-medium text-gray-400">
            Redakce: <EditorialTitle editorial={editorial} />
          </DropdownMenuLabel>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem className="flex justify-between" onClick={handleSignOut}>
          <p className="font-bold text-gray-700">Odhlásit</p>
          <LogOut className="size-4" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default UserMenu
