import { CSSProperties, forwardRef } from 'react'
import { GripVertical } from 'lucide-react'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { DraggableAttributes } from '@dnd-kit/core'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { type Story } from '@/api/stories/types'
import useOrderContext from '@/hooks/useOrderContext'
import { Button } from '@/components/shadcn/Button'
import { cn } from '@/utils/shadcn/cn'
import RoutePaths from '@/routes/routes'
import StoryCardBar from './StoryCardBar'

import StoryCardActions from './StoryCardActions'
import StoryPreviewImage from './StoryPreviewImage'

interface StoryCardProps {
  story: Story
  listeners?: SyntheticListenerMap
  attributes?: DraggableAttributes
  isDragging?: boolean
  style?: CSSProperties
}

export const PreviewStoryCard = forwardRef<HTMLDivElement, StoryCardProps>(({ story, listeners, attributes, isDragging, style }, ref) => {
  const { slug } = useParams()
  const navigate = useNavigate()

  const { orderEnabled } = useOrderContext()

  return (
    <div ref={ref} style={style} className="relative">
      <div
        className={cn(
          'flex w-[500px] cursor-pointer space-x-4 rounded-2xl bg-gray-100 p-6 transition-colors hover:bg-gray-50',
          orderEnabled && (isDragging ? 'opacity-100' : 'opacity-50')
        )}
        role="button"
        aria-hidden="true"
        tabIndex={0}
        onClick={() => navigate(generatePath(RoutePaths.STORY_DETAIL, { storyId: story.id, slug: slug! }))}
      >
        <StoryPreviewImage pages={story.pages} />
        <div className="flex flex-col justify-between">
          <StoryCardBar story={story} />
          <StoryCardActions story={story} />
        </div>
      </div>
      {orderEnabled && (
        <Button variant="outline" size="icon" {...listeners} {...attributes} className="absolute right-4 top-4">
          <GripVertical className="size-5" />
        </Button>
      )}
    </div>
  )
})

PreviewStoryCard.displayName = 'PreviewStoryCard'
export default PreviewStoryCard
