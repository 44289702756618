import { CircleHelp, FilePlus, FileQuestion, Globe, QrCode } from 'lucide-react'
import Outlink from './Outlink'

const LINKS = {
  vizitky: 'https://vizitky.cnchub.io/',
  kvizy: 'https://cms.kviz.cnchub.io/',
  intranet: 'https://sites.google.com/cncenter.cz/intranet/',
  ring: 'https://cms.cncenter.cz/',
  support: 'https://sites.google.com/cncenter.cz/intranet/u%C5%BEite%C4%8Dn%C3%A9-informace/hl%C3%A1%C5%A1en%C3%AD-incident%C5%AF',
} as const

const OutlinkBar = () => {
  return (
    <div className="flex gap-3 bg-gray-100 p-3 px-10">
      <Outlink href={LINKS.vizitky} Icon={QrCode} text="Vizitky" isExternal />
      <Outlink href={LINKS.kvizy} Icon={FileQuestion} text="Kvízy" isExternal />
      <Outlink href={LINKS.intranet} Icon={Globe} text="Intranet" isExternal />
      <Outlink href={LINKS.ring} Icon={FilePlus} text="RING" isExternal />
      <div className="flex-1" />
      <Outlink href={LINKS.support} Icon={CircleHelp} text="Support" isExternal className="bg-gray-100 hover:bg-white" />
    </div>
  )
}

export default OutlinkBar
